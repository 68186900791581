import "./frontend.scss"
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { APlayer } from "aplayer-react";
import "aplayer/dist/APlayer.min.css";
import axios from 'axios';

const divsToUpdate = document.querySelectorAll(".national-day-calendar-plugin")

divsToUpdate.forEach(function (div) {
    const data = JSON.parse(div.querySelector("pre").innerHTML)
    ReactDOM.render(<NationalDayCalendar {...data} />, div)
    div.classList.remove("national-day-calendar-plugin")
})

function NationalDayCalendar(props) {

    const [feedData, setFeedData] = useState(undefined);
    const [nationalDays, setNationalDays] = useState(undefined);

    const get_date = () => {
        // // Create a new Date object
        // let currentDate = new Date();

        // // Define an array of month names
        // let monthNames = [
        // "January", "February", "March", "April", "May", "June",
        // "July", "August", "September", "October", "November", "December"
        // ];

        // // Get the current month, day, and year
        // let month = monthNames[currentDate.getMonth()];
        // let day = currentDate.getDate();
        // let year = currentDate.getFullYear();

        // // Display the current date in the desired format
        // return month + " " + day + ", " + year;

        const options = { timeZone: 'America/New_York', month: 'long', day: 'numeric', year: 'numeric' };
        return new Date().toLocaleDateString('en-US', options);


    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(`${wpData.siteUrl}/wp-json/ndcalendar/shorts`);
            let items = await response.data;

            if (response.data.status !== 'error') {
                let audios = items.map(item => ({
                    name: item.title,
                    url: item.enclosureUrl,
                    cover: item.itunesImage,
                    artist: 'Destination Celebration'
                }));
                
                setFeedData(audios);

            }
            
            
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(`${wpData.siteUrl}/wp-json/ndcalendar/today`);
            let items = await response.data;

            if (response.data.status !== 'error') {

                setNationalDays(items.national_days);

            }
            
            
        };
    
        fetchData();
    }, []);

    return (
        <div className="nationaldaycalendar-frontend">
            <h3>National Day Calendar &reg;</h3>
            <div className="ndc-player">
                {feedData ? (
                    <>
                    <APlayer audio={feedData} autoPlay={props.autoplay} listFolded="true" />
                    </>
                ) : ('')}
            </div>
            <div className="t-date">{get_date()}</div>
            <div className="today-national-days">
                {nationalDays && nationalDays.map((item, index) => {
                    return (
                    <div className="ndc-post">
                        <div className="nat-badge">National Day</div>
                        <h3><a href={item.url}>{item.title}</a></h3>
                    </div>

                    )
                })}
            </div>
            <a className="ndc-btn" href="https://www.nationaldaycalendar.com/year-at-a-glance" target="_blank">Tomorrow's Celebrations <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M507-480 384-357l56 57 180-180-180-180-56 57 123 123ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg></a>
            
        </div>
    );
}